import React, { useState } from 'react';
import { connect } from 'react-redux';
import { UPDATE_PLATFORM_REGISTRATION } from '../store/actions';
import { graphql } from 'gatsby';
import Button from 'components/button/button';
import Image from 'components/image/image';
import DataPrepSubpageWrapper from 'components/data-prep/data-prep-subpage-wrapper';
import 'components/data-prep/data-prep.sass';

const UserWelcome = ({ data, data_prep_registration_reducer, updatePlatformRegistration }) => {
  const [ errorMessage, setErrorMessage ] = useState(null);
  const d = data.landingPagesYaml.registration;
  const { step, first_name, user_details } = data_prep_registration_reducer;


  const sendToPlatform = () => {
    if (user_details && user_details.project_path) {
      window.location.href = process.env.PLATFORM_HOST + '/' + user_details.project_path;
    } else {
      console.error('User did not get project_path from /register endpoint');
      setErrorMessage("We're sorry, it seems there was an error. Please contact support@kortical.com and we will give you access to the platform as soon as possible.")
    }
  }

  return (
    <DataPrepSubpageWrapper step={step} data={d} updatePlatformRegistration={updatePlatformRegistration}>
      <div className="UserComplete k-background-box">
        <Image image_url="layout/bg-data-prep-stripes.svg"
              alt={`background stribes`}
              className="UserComplete__background UserComplete__background--stripes"/>

        <Image image_url="layout/bg-data-prep-stripes2.svg"
              alt={`background stribes`}
              className="UserComplete__background UserComplete__background--stripes2"/>

        <Image image_url="layout/bg-diamond.svg"
              alt={`background square`}
              className="UserComplete__background UserComplete__background--square"/>
        <Image image_url="layout/bg-diamond--blue.svg"
              alt={`background square`}
              className="UserComplete__background UserComplete__background--square--blue"/>

        <Image image_url={d.completed.lead_photo}
              alt={`Andy gray CEO of Kortical smiling`}
              className="UserComplete__leadership"/>

        <div className="UserComplete__content">
          <h4>Hi {first_name},</h4>
          <p>We’re excited that you joined our Beta programme. We’re committed to making the best data-science tools around and you will see that reflected in what we’ve made. Your feedback is going to help us take it to the next level, so I want you to know that we read every word of feedback and it’s hugely appreciated!</p>
          <p>Please get stuck in and see how it works. We’re still getting our name out there, so if you’re feeling it, please do share and spread the word.</p>
          <p>Thanks,</p>

          <div className="UserComplete__content__signature d-flex">
            <Image image_url={'content/icon-andy-signature/icon-andy-signature.png'}
                  alt={`Andy Gray CEO of Kortical's signature`}/>
              <p>&amp; Team</p>
          </div>

          <Image image_url={d.completed.team_photo}
                  alt={`Kortical team photo smiling`}
                  className="my-4"/>

          <Button callback={sendToPlatform}
                  id={`MLDataPrepWelcome__send-to-platform`}
                  className="btn k-text--heading-work btn-block btn-xs-inline-block d-flex align-items-center justify-content-center text-normal p-2 w-75 k-margin-0-auto">
            Let's Go!
          </Button>
          <p className="text-red mt-2">{errorMessage}</p>
        </div>
      </div>
    </DataPrepSubpageWrapper>
  )
}

const mapStateToProps = state => {
  return {
    data_prep_registration_reducer: state.registrationReducer.data_prep[state.registrationReducer.data_prep.length - 1]
  }
}

const mapDispatchToProps = dispatch => ({
  updatePlatformRegistration: (field, data) => {
    dispatch(UPDATE_PLATFORM_REGISTRATION(field, data))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(UserWelcome)

export const pageQuery = graphql`
  query {
    landingPagesYaml {
      registration {
        title
        subtitle
        section
        completed {
          lead_photo
          team_photo
        }
      }
    }
  }
`